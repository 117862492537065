import React, { useState, useEffect } from "react";
import axios from "./axios";
import AppBar from "@mui/material/AppBar";
import Div from "@mui/material/Divider";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import sharinganAnimation from "./assets/sharingans-animated.gif";
import "./App.css";

function sleep(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break;
    }
  }
}

function App() {
  useEffect(() => console.log("spinner", spinner));

  // Update this to unpack images that get set around as base64
  const [imageData, setImageData] = useState(null);
  // CircularProgress
  const [spinner, setSpinner] = useState(false);

  function getData() {
    console.log("start of getData");
    setSpinner(true);
    axios({
      method: "GET",
      url: "/generate-image",
    })
      .then((response) => {
        const resData = response.data;
        setImageData(resData["img"]);
        sleep(750);
        setSpinner(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  return (
    <Box className="App" width="100vw" justifyContent="center">
      <Box sx={{ flexGrow: 1, background: "#FFE5DF" }}>
        <AppBar
          position="static"
          style={{
            backgroundImage:
              "linear-gradient(to bottom, #FFECE7, 85%, #f3ecec)",
          }}
        >
          <Toolbar style={{ minHeight: "15vh" }}>
            <Box
              // variant="h4"
              fontSize="5vh"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "500", color: "#1F1717" }}
              style={{ fontFamily: "Apercu" }}
            >
              sharinGAN
              <sup>
                <small>
                  <small>
                    <small>
                      <a href="https://leonardtang.me">[1]</a>
                    </small>
                  </small>
                </small>
              </sup>
              : naruto x a.i.
              <sup>
                <small>
                  <small>
                    <small>
                      <a href="https://leonardtang.me">[2]</a>
                    </small>
                  </small>
                </small>
              </sup>
              {/* , an exercise in few-shot image synthesis */}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <Box className="meat" justifyContent="center">
        <Box
          className="sharingan-synth"
          marginTop="-15vh"
          fontWeight="500"
          fontSize="4vh"
        >
          click to synthesize novel sharingan
          <sup>
            <small>
              <small>
                <small>
                  <a href="https://naruto.fandom.com/wiki/Sharingan">[3]</a>
                </small>
              </small>
            </small>
          </sup>
        </Box>
        <Box>
          <div>
            {spinner ? (
              <Box
                display="flex"
                height="263px"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size="75px" thickness="1.7" disableShrink />
              </Box>
            ) : imageData ? (
              <Box
                display="flex"
                height="263px"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className="sharingan-img"
                  src={"data:image/png;base64," + imageData}
                  alt="Card image cap"
                />
              </Box>
            ) : (
              <Div height="400px">
                <img src={sharinganAnimation} alt="Loading..." />
              </Div>
            )}
          </div>

          <Button
            variant="outlined"
            onClick={getData}
            // color="secondary"
            size="large"
            style={{
              border: "2px solid",
              textTransform: "lowercase",
              marginTop: "0.7vh",
            }}
            sx={{ fontWeight: "500" }}
          >
            Synthesize
          </Button>

          {/* About */}
        </Box>

        <Box height="10vw"></Box>

        {/* <Box marginTop="20vh" width="80vw"> */}
        <Box
          // TODO: USE CHROME DEV TOOLS!
          width="100vw"
          display="flex"
          marginBottom="-10vw"
          sx={{
            fontSize: "1.65vh",
            // borderRight: "1px solid grey",
            // borderBottom: "1px solid grey",
            // borderLeft: "1px solid grey",
            // height: 100,
            // display: "flex",
            // justifyContent: "flex-start",
            justifyContent: "center",
          }}
        >
          <Box width="85vw" marginLeft="-4vw">
            <ol>
              <li>
                Generative Adversarial Networks (see{" "}
                <a href="https://arxiv.org/pdf/1406.2661.pdf">
                  <u>paper</u>
                </a>
                )
              </li>
              <li>
                an exercise in few-shot image synthesis; see my approach
                detailed{" "}
                <a href="https://leonardtang.me/posts/SharinGAN/">
                  <u>here</u>
                </a>
              </li>
              <li>
                yes, i really made this pun:{" "}
                <a href="https://naruto.fandom.com/wiki/Sharingan">
                  <u>https://naruto.fandom.com/wiki/Sharingan</u>
                </a>
              </li>
            </ol>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default App;

// TODO: prevent load until GIF is loaded?
